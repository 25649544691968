/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Layout, SEO } from "../components"
import { graphql } from "gatsby"
import { 
  ContactIntro,
  ConctactForm
} from "../containers"

const ContactPage = ({data}) => {
  const { markdownRemark: page } = data
  return(
    <Layout>
      <SEO title="Contact" />
      <ContactIntro title={page.frontmatter.title} 
      locationtitle={page.frontmatter.locationtitle} 
      location={page.frontmatter.location} 
      contacttitle={page.frontmatter.contacttitle}
      email={page.frontmatter.email}
      mobile={page.frontmatter.mobile}
      sx={{ pb: ['0',null,'72px']}}></ContactIntro>
      <ConctactForm title={page.frontmatter.formtitle}></ConctactForm>
    </Layout>
  )
}  

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const query = graphql`
  {
    markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
      id
      html
      frontmatter {
        title
        location
        locationtitle
        contacttitle
        email
        mobile
        formtitle
      }
    }
  }
`